<template>
  <b-card-code>
    <div class="custom-search d-flex justify-content-start">
      <b-row>
        <b-col md="3">
          <b-card-text>
            <span>Select Class </span>
          </b-card-text>
          <select
              name="classes_id"
              v-model="classes_id"
              :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
              class="form-control"
          >
            <option :value="null">Select One</option>
            <option
                v-for="(cls, key) in classes"
                :value="cls.id"
                :key="key"
            >
              {{ cls.name }}
            </option>
          </select>
        </b-col>
        <!-- <b-col md="2">
          <b-card-text>
            <span>Select Version</span>
          </b-card-text>
          <select
              name="version_id"
              v-model="version_id"
              :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
              class="form-control"
          >
            <option :value="null">Select One</option>
            <option
                v-for="(version, key) in versions"
                :value="version.id"
                :key="key"
            >
              {{ version.name }}
            </option>
          </select>
        </b-col> -->
        <b-col md="3">
          <b-card-text>
            <span>Select Student Group</span>
          </b-card-text>
          <select
              name="student_group_id"
              v-model="student_group_id"
              :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
              class="form-control"
          >
            <option :value="null">Select One</option>
            <option
                v-for="(sgrp, key) in student_groups"
                :value="sgrp.id"
                :key="key"
            >
              {{ sgrp.name }}
            </option>
          </select>
        </b-col>
        <b-col md="3">
          <b-card-text>
            <span>Select Wing</span>
          </b-card-text>
          <select
              name="wing_id"
              v-model="wing_id"
              :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
              class="form-control">
            <option :value="null">Select One</option>
            <option
                v-for="(wing, key) in wings"
                :value="wing.id"
                :key="key"
            >
              {{ wing.name }}
            </option>
          </select>
        </b-col>
        <b-col md="3">
          <b-card-text>
            <span>Select Academic Year</span>
          </b-card-text>
          <select
              name="academic_year_id"
              v-model="academic_year_id"
              :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
              class="form-control"
          >
            <option :value="null">Select One</option>
            <option
                v-for="(ayear, key) in academic_years"
                :value="ayear.id"
                :key="key"
            >
              {{ ayear.year }}
            </option>
          </select>
        </b-col>

        <b-col md="3">
          <b-card-text>
            <span>Select Payment Status</span>
          </b-card-text>
          <select
              name="is_paid"
              v-model="is_paid"
              :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
              class="form-control"
          >
            <option :value="null">Select One</option>
            <option value="1">Paid</option>
            <option value="0">Not Paid</option>
          </select>
        </b-col>

        <b-col md="3">
          <b-card-text>
            <span>Parental Occupation</span>
          </b-card-text>
          <select class="form-control" 
            name="occupation_type"
            v-model="occupation_type"
            :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'" >

            <option :value="null" selected>Select One</option>
            <option value="1">Army(Officer)</option>
            <option value="2">Army(AFNS)</option>
            <option value="3">Army(JCO & OR)</option>
            <option value="16">Army(Retd)</option>
            <option value="4">Navy</option>
            <option value="5">Airforce</option>
            <option value="6">Police</option>
            <option value="7">Govt. Official</option>
            <option value="8">Doctor</option>
            <option value="9">Teacher</option>
            <option value="10">Lawer</option>
            <option value="11">Business</option>
            <option value="12">Housewife</option>
            <option value="13">Contractor</option>
            <option value="14">Service</option>
            <option value="15">Others</option>

            </select>
        </b-col>

        <b-col md="3">
          <b-card-text>
            <span>Select Index Range</span>
          </b-card-text>
          <select
              name="index_sequence"
              v-model="index_sequence"
              :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
              class="form-control"
          >
            <option :value="null">Select One</option>
            <option value="1-100">1 to 100</option>
            <option value="101-200">101 to 200</option>
            <option value="201-300">201 to 300</option>
            <option value="301-400">301 to 400</option>
            <option value="401-500">401 to 500</option>
            <option value="501-600">501 to 600</option>
            <option value="601-700">601 to 700</option>
            <option value="701-800">701 to 800</option>
            <option value="801-900">801 to 900</option>
            <option value="901-1000">901 to 1000</option>
            <option value="901-1000">1001 to 1100</option>
            <option value="901-1000">1101 to 1200</option>
          </select>
        </b-col>

      </b-row>

    </div>

    <br/>
    <div class="custom-search d-flex justify-content-end">
      <b-form-group>
        <div class="d-flex align-items-center">
          <label class="mr-1">Search</label>
          <b-form-input
              v-model="searchTerm"
              placeholder="Search"
              type="text"
              class="d-inline-block"
          />
        </div>
      </b-form-group>
    </div>
      <div class="custom-search d-flex justify-content-start">
          <vue-excel-xlsx
              :data="getData"
              :columns="columns10"
              :filename="'student_list'"
              :sheetname="'admission_students'"
          >
              <b>Excel</b>
          </vue-excel-xlsx>
          <vue-json-to-csv :json-data="getData"
                           :csv-title="'My_CSV'"
          >
              <button>
                  <b>CSV</b>
              </button>
          </vue-json-to-csv>
          <button @click="pdfGenerate()">
              <b>PDF</b>
          </button>

           <button @click="downloadAttendanceSheet()">
              <b>ATTENDANCE SHEET</b>
          </button>

      </div>
    <!-- table -->
    <vue-good-table
        style="white-space: nowrap;text-align: center;vertical-align : middle;"
        :columns="columns"
        :rows="admission_students"
        :rtl="direction"
        :search-options="{
        enabled: true,
        externalQuery: searchTerm }"
        :pagination-options="{
        enabled: true,
        perPage:pageLength
      }"
    >
      <template
          slot="table-row"
          slot-scope="props"
      >
        <!-- Column: index -->
        <span v-if="props.column.field === 'id'">
          {{ props.row.originalIndex + 1 }}
        </span>
        <span v-else-if="props.column.field === 'student_image'">
          <img :src="imgShow(props.row.student_image)" alt=""  width="50px" height="50px;"/>
        </span>
        <span v-else-if="props.column.field === 'classes_id'">
          {{ classByID(props.row.classes_id) }}
        </span>
        <span v-else-if="props.column.field === 'version_id'">
          {{ versionByID(props.row.version_id)}}
        </span>
        <span v-else-if="props.column.field === 'wing_id'">
          {{ wingByID(props.row.wing_id)}}
        </span>
        <span v-else-if="props.column.field === 'student_group_id'">
          {{ student_groupByID(props.row.student_group_id)}}
        </span>
        <span v-else-if="props.column.field === 'academic_year_id'">
          {{ academic_yearByID(props.row.academic_year_id)}}
        </span> 
        <span v-else-if="props.column.field === 'is_paid'">
          <b-badge v-if="props.row.is_paid" variant="success" class="text-black-50">Paid</b-badge>
          <b-badge v-else variant="danger" classs="text-black">Not Paid</b-badge>
        </span>
        <!-- Column: Status -->
        <span v-else-if="props.column.field === 'is_active'">
          <b-form-checkbox  :name="'check-button'+props.row.originalIndex" :id="'check-button'+props.row.originalIndex" @change="statusChange(props.row.id)" v-model="props.row.is_active" :value="1" switch>
            </b-form-checkbox>
        </span>
        <span v-else-if="props.column.field === 'action'">
          <b-button variant="success" @click="singlePdfGenerate(props.row.id)">
             <feather-icon icon="DownloadIcon" class="mr-50"/>
          </b-button>
          <b-button v-if="true" v-ripple.400="'rgba(113, 102, 240, 0.15)'" @click="remove(props.row.id, props.row.originalIndex)"
                        v-b-modal.modal-login variant="danger">
                  <feather-icon class="text-body " icon="TrashIcon" size="15" />
              </b-button>
        </span>
      </template>

      <!-- pagination -->
      <template
          slot="pagination-bottom"
          slot-scope="props"
      >
        <div class="d-flex justify-content-between flex-wrap">
          <div class="d-flex align-items-center mb-0 mt-1">
            <span class="text-nowrap ">
              Showing 1 to
            </span>
            <b-form-select
                v-model="pageLength"
                :options="['10','15','20','50','100','500']"
                class="mx-1"
                @input="(value)=>props.perPageChanged({currentPerPage:value})"
            />
            <span class="text-nowrap"> of {{ props.total }} entries </span>
          </div>
          <div>
            <b-pagination
                :value="1"
                :total-rows="props.total"
                :per-page="pageLength"
                first-number
                last-number
                align="right"
                prev-class="prev-item"
                next-class="next-item"
                class="mt-1 mb-0"
                @input="(value)=>props.pageChanged({currentPage:value})"
            >
              <template #prev-text>
                <feather-icon
                    icon="ChevronLeftIcon"
                    size="18"
                />
              </template>
              <template #next-text>
                <feather-icon
                    icon="ChevronRightIcon"
                    size="18"
                />
              </template>
            </b-pagination>
          </div>
        </div>
      </template>
    </vue-good-table>
  </b-card-code>
</template>

<script>
import BCardCode from '@core/components/b-card-code/BCardCode.vue'
import {
  BPagination, BFormGroup, BFormInput, BFormSelect,BBadge,BFormCheckbox,BButton,
    BCol,BCardText,BRow
} from 'bootstrap-vue'
import {required,integer} from '@validations'
import { VueGoodTable } from 'vue-good-table'
import store from '@/store/index'
import apiCall from "@/libs/axios";
import Ripple from 'vue-ripple-directive'
import {mapGetters} from "vuex";
import moment from "moment";
import VueExcelXlsx from "vue-excel-xlsx";
import VueJsonToCsv from 'vue-json-to-csv'
import Vue from "vue";
import download from "downloadjs";

Vue.use(VueExcelXlsx);
export default {
  name:'StudentList',
  components: {
    BCardCode,
    BPagination,
    BFormGroup,BButton,
    BFormInput,BBadge,BFormCheckbox,
    BFormSelect,VueGoodTable,
    VueJsonToCsv,BCol,BCardText,BRow,
  },
  directives: {
    Ripple,
  },
  data() {
    return {
      moment,
      required,
      pageLength: 10,
      dir: false,
      selected_row:{},
      columns: [
        {
          label: 'SL',
          field: 'id',
        },
        {
          label: 'Image',
          field: 'student_image',
        },
        {
          label: 'Index No',
          field: 'roll',
        },
        {
          label: 'Track No',
          field: 'track_no',
        },
        {
          label: 'Mobile No',
          field: 'guardian_mobile',
        },
        {
          label: 'Name',
          field: 'name',
        },
        {
          label: 'Class',
          field: 'classes_id',
        },
        {
          label: 'Version',
          field: 'version_id',
        },
        {
          label: 'Wing',
          field: 'wing_id',
        },
        {
          label: 'Student Group',
          field: 'student_group_id',
        },
        {
          label: 'Academic year',
          field: 'academic_year_id',
        },
        {
          label: 'Payment Status',
          field: 'is_paid',
        },
        {
          label: 'Status',
          field: 'is_active',
        },
        {
          label: 'Action',
          field: 'action',
        },
      ],
      searchTerm: '',
      integer,
      student_group_id:null,
      version_id:null,
      index_sequence: null,
      classes_id:null,
      class_period_id:null,
      academic_year_id:null,
      is_paid:null,
      wing_id:null,
      occupation_type:null,
      columns10: [
            {
              label: 'Roll',
              field: 'Roll',
            },
            {
              label: 'TrackNo',
              field: 'TrackNo',
            },
            {
              label: 'Name',
              field: 'Name',
            },
            {
                label: 'Class',
                field: 'Class',
            },
            {
                label: 'Version',
                field: 'Version',
            },
            {
                label: 'Wing',
                field: 'Wing',
            },
            {
                label: 'Mobile Number',
                field: 'Mobile',
            },
            {
                label: 'Academic Year',
                field: 'AcademicYear',
            },
            {
              label: 'Payment Status',
              field: 'isPaid',
            },
        ],
      mode:'add',
    }
  },
  methods:{
    imgShow(path){
      return process.env.VUE_APP_ENV_RESOURCHURL+path;
    },

    async remove(id,index){
      await this.$swal({
        title: 'Are you sure?',
        text: "You want to remove!",
        icon: 'warning',
        showCancelButton: true,
        confirmButtonText: 'Yes, remove it!',
        customClass: {
          confirmButton: 'btn btn-primary',
          cancelButton: 'btn btn-outline-danger ml-1',
        },
        buttonsStyling: false,
      }).then(result => {
        if (result.value) {
          apiCall.delete(`/admission/remove${id}`).then((response) => {
            if (response.data.status === 'success') {
              this.$toaster.success(response.data.message);
              this.$store.commit('REMOVE_SUBJECT', index);
            } else {
              this.$toaster.error(response.data.message);
            }
          }).catch((error) => {
            if (error.response.status == 422) {
              Object.keys(error.response.data.errors).map((field) => {
                this.$toaster.error(error.response.data.errors[field][0]);
              })
            } else this.$toaster.error(error.response.data.message);
          });
        }
      })
    },
    async pdfGenerate(){
        let ids = this.admission_students.map((item) => item.id);
          let data = new FormData();
         
          data.append('classes_id', this.classes_id);
          data.append('index_sequence', this.index_sequence);
          for (var i = 0; i < ids.length; i++) {
            data.append('ids[]', ids[i]);
          }
        //  var win = window.open('/blank');
          /*change able end*/
          await apiCall.post(`/get/admission/students`,data,{responseType: 'blob'}).then((response)=>{
              const content = response.headers['content-type'];
              download(response.data,'student_list.pdf', content);
           // var url = URL.createObjectURL(response.data);
           // win.location = url;

          }).catch((error) => {
              //win.close();
              this.$toaster.error(error.response.data.message);
          });
      },
      async downloadAttendanceSheet(){
          let studentIdList = this.admission_students.map((item) => item.id);
          let data = new FormData();
          data.append('classes_id', this.classes_id);
          data.append('index_sequence', this.index_sequence);
          
          for (var i = 0; i < studentIdList.length; i++) {
            data.append('ids[]', studentIdList[i]);
          }
       
          await apiCall.post(`/get/admission/attendance`, data,{responseType: 'blob'}).then((response)=>{
              const content = response.headers['content-type'];
              download(response.data,'attendance_sheet.pdf', content);
          }).catch((error) => {
              this.$toaster.error(error.response.data.message);
          });
      },
    async singlePdfGenerate(id){
     // var win = window.open('/blank');
      await apiCall.get(`/get/admission/single/student${id}`,{responseType: 'blob'}).then((response)=>{
        const content = response.headers['content-type'];
        download(response.data,'student_details.pdf', content);
      }).catch((error) => {
          //win.close();
          this.$toaster.error(error.response.data.message);
      });
    },
    statusChange(id){
        apiCall.put(`/admission/student/status/change${id}`).then((response)=>{
          this.$store.dispatch('GET_ALL_ADMISSION_STUDENT');
          this.$swal({
            icon: 'success',
            title: 'Success!',
            text: response.data.message,
            customClass: {
              confirmButton: 'btn btn-success',
            },
          })
        }).catch((error)=>{
          this.$toaster.error(error.response.data.message);
          this.$store.dispatch('GET_ALL_ADMISSION_STUDENT');
        });
      },
  },
  computed: {
    statusVariant() {
      const statusColor = {
        /* eslint-disable key-spacing */
        1 : 'light-success',
        0 : 'light-danger',
        /* eslint-enable key-spacing */
      }
      return status => statusColor[status]
    },
    direction() {
      if (store.state.appConfig.isRTL) {
        // eslint-disable-next-line vue/no-side-effects-in-computed-properties
        this.dir = true
        return this.dir
      }
      // eslint-disable-next-line vue/no-side-effects-in-computed-properties
      this.dir = false
      return this.dir
    },
    ...mapGetters([
        'academic_years','academic_yearByID',
      'classes','classByID','versions','versionByID','student_groups','student_groupByID',
        'wings','wingByID'
    ]),
    admission_students(){
      let stds=this.$store.getters.admission_students;
      if(this.classes_id) stds=stds.filter(item=>item.classes_id===this.classes_id);
      if(this.version_id) stds=stds.filter(item=>item.version_id===this.version_id);
      if(this.student_group_id) stds=stds.filter(item=>item.student_group_id===this.student_group_id);
      if(this.wing_id) stds=stds.filter(item=>item.wing_id===this.wing_id);
      if(this.academic_year_id) stds=stds.filter(item=>item.academic_year_id===this.academic_year_id);
      if(this.is_paid) stds=stds.filter(item=>item.is_paid==this.is_paid);
      if(this.occupation_type) stds=stds.filter(item=>item.occupation_type==this.occupation_type);
      
      if(this.index_sequence) {
       var desiredIndexs = this.index_sequence.split('-');
       stds =  stds.filter(function (el)
          {
            return el.index_sequence >=desiredIndexs[0] &&
                  el.index_sequence <= desiredIndexs[1];
          }
          );
      }
        
      return stds;
    },
      getData(){
          return this.admission_students.map(item => ({
              Roll: item.roll?item.roll:'',
              TrackNo: item.track_no?item.track_no:'',
              Name: item.name?item.name:'',
              Class: item.classes_id?this.classByID(item.classes_id):'',
              Version: item.version_id?this.versionByID(item.version_id):'',
              Wing: item.wing_id?this.wingByID(item.wing_id):'',
              Mobile: item.guardian_mobile? item.guardian_mobile:'',
              AcademicYear: item.academic_year_id?this.academic_yearByID(item.academic_year_id):'',
              isPaid: item.is_paid?'Yes':'No',
          }));
      },
    user(){
      return JSON.parse(localStorage.getItem('userData'))
    }
  },
  created() {
    this.$store.dispatch('GET_ALL_ADMISSION_STUDENT');
    if(this.classes.length <1) this.$store.dispatch('GET_ALL_CLASSES');
    if(this.versions.length <1) this.$store.dispatch('GET_ALL_VERSION');
    if(this.wings.length <1) this.$store.dispatch('GET_ALL_WING');
    if(this.student_groups.length <1) this.$store.dispatch('GET_ALL_STUDENT_GROUP');
    if(this.academic_years.length <1) this.$store.dispatch('GET_ALL_ACADEMIC_YEAR');
  },
}
</script>
<style lang="scss" >
@import '@core/scss/vue/libs/vue-good-table.scss';
@import '@core/scss/vue/libs/vue-sweetalert.scss';
</style>